import config from '../../config';

const { imageResizeRoot } = config;

const resizeImage = (
  url = {},
  { webp, mediaReady = true, ...options } = {}
) => {
  const imagePath = (url && url.s3_web_path) || (url && url.url) || url;
  const webpFormat = webp ? { format: 'webp' } : {};

  if (!imagePath || !mediaReady) return '';

  const defaults = {
    width: '500',
    height: '500',
    fit: 'inside',
    quality: 60,
    withoutEnlargement: true,
    ...webpFormat,
    ...options,
  };

  const params = Object.keys(defaults)
    .map(key => `${key}_${defaults[key]}`)
    .join(',');

  return `${imageResizeRoot}/${params}/${imagePath}`;
};

export default resizeImage;
