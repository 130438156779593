import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import { REVIEW_STATES } from '../../constants';
import { RoundIcon } from '.';

const StyledDescription = styled(Text)`
  color: ${props => props.theme.shade.shade800};
  font-size: 12px;
  line-height: 1.1em;
  margin-top: 4px;
`;

const StateIcon = ({ status, text }) => {
  const getStatus = REVIEW_STATES.reduce((a, b) => {
    if (b.value === status) {
      return b;
    }
    return a;
  }, {});
  return (
    <Container display="flex" flexDirection="column" alignItems="center">
      <RoundIcon
        datastate={getStatus.id}
        className={getStatus.color}
        size="25px"
        iconProps={{
          className: getStatus.icon,
          iconSize: '24px',
        }}
      />
      <StyledDescription>{text && getStatus.text}</StyledDescription>
    </Container>
  );
};

StateIcon.propTypes = {
  status: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

StateIcon.defaultProps = {
  text: undefined,
};

export default StateIcon;
