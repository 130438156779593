import React, { useContext, useCallback, Fragment } from 'react';
import styled from 'styled-components';
import useInfiniteScroll from 'ls-common-client/src/hooks/useInfiniteScroll';
import Container from 'ls-common-client/src/components/Container';
import Table from 'ls-common-client/src/components/Table';
import TableRow from 'ls-common-client/src/components/TableRow';
import TableHeaderCell from 'ls-common-client/src/components/TableHeaderCell';
import Animation from 'ls-common-client/src/components/Animation';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import { AppContext } from '../../../../context';
import { loader } from '../../../../animations';
import ReviewDialog from '../ReviewDialog';
import Review from './Review';

const StyledTable = styled(Table)`
  box-shadow: 0 0 3px 0 rgb(0 0 0 / 10%);
`;

const StyledTableHeaderCell = styled(TableHeaderCell)`
  font-size: 14px;
  padding: 10px 15px;
  color: #fff;
  font-weight: 600;
  background-color: ${props => props.theme.background.background800};
  &.isSortable {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &.isRating {
    width: 107px;
  }
  &.isPaid {
    width: 58px;
  }
  &.isDate {
    min-width: 155px;
  }
  &.isReview {
    width: 100%;
    max-width: 310px;
  }
  &.isBusiness {
    min-width: 260px;
  }
`;

const StyledIcon = styled(Icon)`
  &.icon-generalcaretdownmedium,
  &.icon-generalcaretupmedium {
    line-height: 0.3em;
    height: auto;
    position: relative;
    top: 2px;
  }
`;

const Reviews = () => {
  const {
    reviews: {
      filters,
      reviews,
      pageInfo,
      loading,
      fetchMore,
      fetchReviews,
      resetLoading,
      moreLoading,
      setReviewModalId,
    },
  } = useContext(AppContext.Context);

  const { sortDirection, sort } = filters || {};
  const { hasNextPage, endCursor } = pageInfo || {};

  const nextPage = useCallback(() => {
    if (hasNextPage && !loading && !moreLoading) {
      fetchMore({
        after: endCursor,
      });
    }
  }, [hasNextPage, loading, moreLoading, endCursor]);

  useInfiniteScroll(nextPage);

  return (
    <>
      <Container
        display="flex"
        flexDirection="row"
        justifyContent="center"
        height="100%"
        width="100%"
      >
        <Container maxWidth="1110px" width="100%" padding="5px 0 100px">
          <StyledTable>
            <thead>
              <TableRow>
                <StyledTableHeaderCell
                  className="isSortable isDate"
                  onClick={() => {
                    fetchReviews({
                      after: null,
                      sort: 'date',
                      sortDirection:
                        sort === 'date' && sortDirection === 'asc'
                          ? 'desc'
                          : 'asc',
                    });
                  }}
                >
                  Date
                  <StyledIcon
                    style={{
                      color: '#ffffff',
                    }}
                    className={`ls-icon ${
                      sort === 'date' && sortDirection === 'asc'
                        ? 'icon-generalcaretupmedium'
                        : 'icon-generalcaretdownmedium'
                    }`}
                    iconSize="medium"
                  />
                </StyledTableHeaderCell>
                <StyledTableHeaderCell className="isImages">
                  Images
                </StyledTableHeaderCell>
                <StyledTableHeaderCell className="isReview">
                  Review
                </StyledTableHeaderCell>
                <StyledTableHeaderCell
                  className="isSortable isRating"
                  onClick={() => {
                    fetchReviews({
                      after: null,
                      sort: 'rating',
                      sortDirection:
                        sort === 'rating' && sortDirection === 'asc'
                          ? 'desc'
                          : 'asc',
                    });
                  }}
                >
                  Rating
                  <StyledIcon
                    style={{
                      color: '#ffffff',
                    }}
                    className={`ls-icon ${
                      sort === 'rating' && sortDirection === 'asc'
                        ? 'icon-generalcaretupmedium'
                        : 'icon-generalcaretdownmedium'
                    }`}
                    iconSize="medium"
                  />
                </StyledTableHeaderCell>
                <StyledTableHeaderCell className="isBusiness">
                  Business
                </StyledTableHeaderCell>
                <StyledTableHeaderCell className="isPaid">
                  Paid
                </StyledTableHeaderCell>
                <StyledTableHeaderCell>Status</StyledTableHeaderCell>
              </TableRow>
            </thead>
            <tbody>
              {!loading && !reviews.length && (
                <tr>
                  <td colSpan="7" valign="top">
                    <Container
                      display="flex"
                      justifyContent="center"
                      width="100%"
                      alignItems="start"
                      minHeight="800px"
                    >
                      <Text marginTop="normal">No results found.</Text>
                    </Container>
                  </td>
                </tr>
              )}
              {!resetLoading &&
                !loading &&
                reviews.map(review => (
                  <Fragment key={review.id}>
                    <Review
                      review={review}
                      onClick={id => setReviewModalId(id)}
                    />
                  </Fragment>
                ))}

              {(!!resetLoading || !!loading || !!moreLoading) && (
                <tr>
                  <td colSpan="7" valign="top">
                    <Container
                      display="flex"
                      justifyContent="center"
                      width="100%"
                      alignItems="start"
                      minHeight="200px"
                    >
                      <Animation data={loader} width="180px" />
                    </Container>
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan="7" valign="top" />
              </tr>
            </tbody>
          </StyledTable>
        </Container>
      </Container>
      <ReviewDialog />
    </>
  );
};

export default Reviews;
