import React from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import styled from 'styled-components';

const StyledContainer = styled(Container)`
  form {
    width: 100%;
  }

  label {
    height: 42px;
  }

  input {
    height: 42px;
  }

  input:hover:not([readonly]),
  input:focus:not([readonly]) {
    border-color: ${props => props.theme.border.normal} !important;
  }

  button {
    height: 40px;
  }
`;

const StyledFormContainer = styled(Container)`
  > label {
    margin-right: 16px;
    &.fullWidth {
      flex: 1;
    }
  }
  > div {
    margin-right: 16px;
    flex: 1 0 10%;
    max-width: 240px;
  }
  button:last-child {
    margin-left: 16px;
  }
`;

const FilterSearch = ({ children, onSubmit }) => (
  <StyledContainer
    paddingBottom="large"
    paddingTop="large"
    backgroundColor="background300"
  >
    <form onSubmit={onSubmit} noValidate autoComplete="off">
      <StyledFormContainer display="flex" alignItems="flex-start">
        {children}
      </StyledFormContainer>
    </form>
  </StyledContainer>
);

FilterSearch.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func,
};

FilterSearch.defaultProps = {
  children: undefined,
  onSubmit: () => {},
};

export default FilterSearch;
