const defaults = require('./defaults');

const { auth } = defaults;

module.exports = {
  ...defaults,
  debug: false,
  mode: 'production',
  sourceMap: 'source-map',
  hot: false,
  api: 'https://services.lsapis.com.au',
  token: {
    ...defaults.token,
    cookie: {
      domain: '.lsapps.com.au',
    },
  },
  auth: {
    ...auth,
    domain: 'auth.localsearch.com.au',
    audience: 'https://performance.lsapis.com.au',
    clientID: '8rzX1zD2ConAfAmJ7XG2ttSWT6XgQwh6',
    redirectUri: 'https://reviews.lsapps.com.au',
  },
  imageResizeRoot: 'https://d2xzxktm3qm7rt.cloudfront.net',
  webUrlRoot: 'https://www.localsearch.com.au',
  deploy: {
    domain: 'reviews.lsapps.com.au',
    zoneID: 'ZWYDMH3QKW5TI',
    certificateArn:
      'arn:aws:acm:us-east-1:708891215413:certificate/23f1e0fa-2b68-4156-b4e2-5f2f93515f4e',
  },
};
