const defaults = require('./defaults');

const { auth } = defaults;

module.exports = {
  ...defaults,
  hot: false,
  imageResizeRoot: 'https://dkw8fcvpqki80.cloudfront.net',
  api: 'https://development.services.lsapis.com.au',
  webUrlRoot: 'https://development.ls-www.localsearch.cloud',
  auth: {
    ...auth,
    domain: 'staging.auth.localsearch.cloud',
    audience: 'https://performance.lsapis.com.au',
    clientID: '6RJIGeWY1kXyRmwavFSitmZWZW8IAMRo',
    redirectUri: 'https://development.reviews.lsapps.com.au',
  },
  deploy: {
    domain: 'development.reviews.lsapps.com.au',
    zoneID: 'Z33FF7PYMSS361',
    certificateArn:
      'arn:aws:acm:us-east-1:789417954668:certificate/577cba82-08c9-4f72-bf6d-7fbabcfa5690',
  },
};
