import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Dialog from 'ls-common-client/src/components/Dialog';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import { Button } from '../../../../common';

const StyledText = styled(Text)`
  color: ${props => props.theme.error.normal};
`;

const ExitDialog = ({ show, setShow, onReviewShow, onSubmit, formValid }) => {
  return (
    <Dialog
      show={show}
      header={<div />}
      padding="medium"
      minWidth="447px"
      maxWidth="447px"
      minHeight="283px"
    >
      <Container
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="218px"
      >
        <Container
          display="flex"
          justifyContent="space-between"
          marginBottom="normal"
        >
          <Text
            width="120px"
            flex="1 0 auto"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            fontSize="22px"
            fontWeight="600"
          >
            Don&apos;t forget to save!
          </Text>
          <EmptyButton
            type="button"
            onClick={() => {
              setShow(false);
            }}
          >
            <Icon iconSize="xxlarge" className="ls-icon icon-generalxsmall" />
          </EmptyButton>
        </Container>
        <Container flex="1">
          <Text fontSize="normal">
            {`You're about to leave edit mode without saving. Doing so will lose
            any changes you've made.`}
          </Text>
        </Container>
        {!formValid() && (
          <Container flex="1">
            <StyledText fontSize="normal">
              Please fix errors before saving.
            </StyledText>
          </Container>
        )}
        <Container display="flex" justifyContent="space-between">
          <Button
            margin="0"
            width="auto"
            fontWeight="normal"
            border="1px solid #4a4a4a"
            flex="1 0 50%"
            maxWidth="50%"
            color="normal"
            type="button"
            onClick={() => {
              setShow(false);
              onReviewShow(false);
            }}
          >
            Exit Without Saving
          </Button>
          <Button
            secondary
            margin="0"
            width="auto"
            fontWeight="normal"
            flex="1 0 47%"
            maxWidth="47%"
            type="button"
            disabled={!formValid()}
            onClick={() => onSubmit()}
          >
            Save and Exit
          </Button>
        </Container>
      </Container>
    </Dialog>
  );
};

ExitDialog.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  onReviewShow: PropTypes.func,
  onSubmit: PropTypes.func,
  formValid: PropTypes.func,
};

ExitDialog.defaultProps = {
  show: undefined,
  setShow: () => {},
  onReviewShow: () => {},
  onSubmit: () => {},
  formValid: () => {},
};

export default ExitDialog;
