import React, { useContext, useEffect, useMemo, useCallback } from 'react';
import useForm from 'ls-common-client/src/hooks/useForm';
import Container from 'ls-common-client/src/components/Container';
import DropDown from 'ls-common-client/src/components/DropDown';
import moment from 'moment';
import styled from 'styled-components';
import { AppContext } from '../../../../context';
import { REVIEW_STATES } from '../../../../constants';
import isEmail from '../../../../lib/isEmail';
import { FilterSearch, DatePicker, Input, Button } from '../../../common';
import BusinessSuggest from './BusinessSuggest';
import SearchLabel from './SearchLabel';

const StyledDropdown = styled(DropDown)`
  height: 42px;
  max-width: 210px !important;
  button,
  button:last-child {
    border-color: ${props => props.theme.border.normal};
    margin-left: 0;
  }
  ${props =>
    props.disabled &&
    `
    opacity: 0.8;
    cursor: not-allowed;
    a {
      pointer-events: none;
      border-color: ${props.theme.border.normal};
    }
  `}
`;

const StyledClearButton = styled(Button)`
  &:hover {
    border: solid 1px ${props => props.theme.background.background600};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
`;

const SearchFilters = () => {
  const {
    reviews: { filters, fetchReviews, activeTab },
  } = useContext(AppContext.Context);
  const { deleted } = filters || {};

  const fetch = ({ searchValue, startDate, endDate, reviewState: state }) => {
    fetchReviews(
      {
        listingId: !isEmail(searchValue) && searchValue ? searchValue : null,
        email: isEmail(searchValue) && searchValue ? searchValue : null,
        reviewState: deleted || !state || state === 'all' ? null : state,
        startDate: startDate ? moment(startDate).toISOString() : null,
        endDate: endDate ? moment(endDate).toISOString() : null,
        after: null,
      },
      true
    );
  };

  const { setValue, values, onSubmit, reset, changed } = useForm({
    defaults: {
      searchValue: '',
      reviewState: 'pending',
      startDate: '',
      endDate: '',
    },
    onSubmit: async payload => {
      try {
        await fetch(payload);
      } catch (err) {
        reset();
        throw err;
      }
    },
  });

  useEffect(() => {
    setValue({
      name: 'reviewState',
      value: activeTab || 'all',
    });
    return () => {};
  }, [activeTab]);

  const today = new Date();

  const stateDropdown = useMemo(() => {
    return REVIEW_STATES.reduce(
      (a, b) => {
        return a.concat({
          ...b,
          selected: values.reviewState === b.value,
          text: b.text,
        });
      },
      [
        {
          value: 'all',
          selected: values.reviewState === 'all',
          text: 'All Reviews',
        },
      ]
    );
  }, [values.reviewState]);

  const onClear = useCallback(async () => {
    reset();
    setValue({
      name: 'reviewState',
      value: activeTab || 'all',
    });
    if (changed()) {
      await fetch({
        deleted,
        reviewState: deleted ? null : activeTab || 'all',
      });
    }
  }, [deleted, activeTab, changed()]);

  return (
    <Container
      display="flex"
      flexDirection="row"
      justifyContent="center"
      height="100%"
      width="100%"
    >
      <Container maxWidth="1110px" width="100%">
        <FilterSearch onSubmit={onSubmit}>
          <BusinessSuggest value={values.searchValue} setValue={setValue} />
          <SearchLabel display="flex" flexDirection="row">
            <DatePicker
              maxDate={today}
              format="dd/MM/yyyy"
              monthPlaceholder="mm"
              dayPlaceholder="dd"
              yearPlaceholder="yyyy"
              setValue={value => {
                if (value) {
                  const [startDate, endDate] = value;
                  setValue({
                    name: 'startDate',
                    value: startDate,
                  });
                  setValue({
                    name: 'endDate',
                    value: endDate,
                  });
                } else {
                  setValue({
                    name: 'startDate',
                    value: '',
                  });
                  setValue({
                    name: 'endDate',
                    value: '',
                  });
                }
              }}
              value={
                values.startDate && values.endDate
                  ? [values.startDate, values.endDate]
                  : null
              }
            />
          </SearchLabel>
          <StyledDropdown
            data={stateDropdown}
            onSelected={item => {
              setValue({
                name: 'reviewState',
                value: item.value,
              });
            }}
            disabled={activeTab || deleted}
          />
          <Input value={values.reviewState || ''} type="hidden" />
          <StyledClearButton
            margin="0"
            width="auto"
            type="button"
            fontWeight="normal"
            color="text300"
            border="1px solid #9b9b9b"
            backgroundColor="normal"
            onClick={onClear}
          >
            Clear
          </StyledClearButton>
          <Button
            primary
            margin="0"
            width="auto"
            type="submit"
            fontWeight="normal"
          >
            Search
          </Button>
        </FilterSearch>
      </Container>
    </Container>
  );
};

export default SearchFilters;
