import { gql, useQuery } from '@apollo/client';

const query = gql`
  query ReviewResponse($reviewId: String!) {
    reviewResponse(reviewId: $reviewId) {
      id
      message
    }
  }
`;

const useReviewResponse = variables => {
  const { fetch: fetchReviewResponse, data, loading } = useQuery(query, {
    variables,
  });

  const { reviewResponse: response } = data || {};

  return {
    response,
    fetchReviewResponse,
    loading,
  };
};

export default useReviewResponse;
