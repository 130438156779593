import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import { AppContext } from '../../../context';
import { REVIEW_STATES } from '../../../constants';
import { util } from '../../../lib';

const { capitalize } = util;

const StyledTabMenu = styled(Container)`
  flex: 1;
  display: flex;
  font-size: 14px;
  .border {
    display: block;
    background: none;
    width: 100%;
    height: 2px;
    margin-top: 20px;
  }
`;

const StyledButton = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  margin-right: 28px;
  color: ${props => props.theme.shade.shade100};
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  font-size: 14px;
  ${props =>
    props.active &&
    `
    opacity: 1;
    .border {
      background: ${props.theme.tertiary.tertiary100};
    }
  `}
  &:hover {
    cursor: pointer;
    &:last-child {
      &:after {
        opacity: 1;
      }
    }
  }
  &[disabled] {
    cursor: not-allowed;
  }
`;

const FilterTabs = () => {
  const {
    reviews: {
      fetchReviews,
      filters,
      loading,
      resetLoading,
      moreLoading,
      setActiveTab,
      activeTab,
    },
  } = useContext(AppContext.Context);
  const { deleted } = filters;

  const tabs = useMemo(
    () =>
      REVIEW_STATES.reduce(
        (a, b) => {
          if (
            b.value === 'pending' ||
            b.value === 'approved' ||
            b.value === 'notApproved'
          ) {
            return a.concat({
              ...b,
              activeClass: activeTab === b.value,
              text: capitalize(b.description.replace(/_/g, ' ')),
              onClick: () => {
                setActiveTab(b.value);
                fetchReviews(
                  {
                    reviewState: b.value,
                  },
                  true
                );
              },
            });
          }
          return a;
        },
        [
          {
            value: 'all',
            activeClass: !activeTab && !deleted,
            text: 'All Reviews',
            onClick: () => {
              setActiveTab(null);
              fetchReviews({ reviewState: null }, true);
            },
          },
        ]
      ),
    [activeTab, deleted]
  );
  return (
    <Container display="flex" flexDirection="row" width="100%">
      <StyledTabMenu>
        {tabs.map(tab => {
          return (
            <StyledButton
              key={tab.value}
              active={tab.activeClass}
              onClick={tab.onClick}
              disabled={loading || resetLoading || moreLoading}
            >
              {tab.text}
              <span className="border" />
            </StyledButton>
          );
        })}
      </StyledTabMenu>
    </Container>
  );
};

export default FilterTabs;
