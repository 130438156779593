import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Image from 'ls-common-client/src/components/Image';
import Dialog from 'ls-common-client/src/components/Dialog';
import Text from 'ls-common-client/src/components/Text';
import { Response } from '../../../../../../images';
import { AppContext } from '../../../../../../context';
import { FormRow, ToggleInput } from '../../../../../common';
import useReviewResponse from '../../../../../../hooks/useReviewResponse';
import OwnerResponseForm from './OwnerResponseForm';

const StyledRemoveButton = styled(EmptyButton)`
  color: ${props => props.theme.error.normal};
  font-size: 11px;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0 10px 9px;
  &:hover {
    font-weight: 600;
  }
`;

const OwnerResponse = ({ reviewId }) => {
  const {
    reviews: { fetchReviews },
  } = useContext(AppContext.Context);
  const [reviewDeleted, onReviewDeleted] = useState();
  const [show, setShow] = useState();
  const { response } = useReviewResponse({
    reviewId,
  });
  const { id, message, deleted } = response || {};

  if (!id || deleted || reviewDeleted) return '';

  return (
    <>
      {!!message && !deleted && (
        <Container marginLeft="xlarge">
          <FormRow
            icon={
              <Image
                src={Response}
                alt="response"
                style={{
                  position: 'relative',
                  top: '-7px',
                }}
              />
            }
          >
            <ToggleInput>
              <Text fontSize="normal">{message}</Text>
              <Container display="flex" justifyContent="flex-end">
                <StyledRemoveButton
                  type="button"
                  onClick={() => setShow(!show)}
                >
                  edit
                </StyledRemoveButton>
              </Container>
            </ToggleInput>
          </FormRow>
        </Container>
      )}
      {response &&
        !deleted &&
        ReactDOM.createPortal(
          <Dialog
            show={show}
            header={<div />}
            padding="medium"
            minWidth="547px"
            maxWidth="547px"
            minHeight="300px"
          >
            <Container
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              minHeight="150px"
            >
              <Container
                display="flex"
                justifyContent="space-between"
                marginBottom="normal"
              >
                <Text
                  width="120px"
                  flex="1 0 auto"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  fontSize="medium"
                  fontWeight="600"
                >
                  Owner Response
                </Text>
                <EmptyButton onClick={() => setShow(false)}>
                  <Icon
                    iconSize="xxlarge"
                    className="ls-icon icon-generalxsmall"
                  />
                </EmptyButton>
              </Container>
              <OwnerResponseForm
                reviewId={reviewId}
                response={response}
                onUpdateSuccess={() => {
                  setShow(false);
                  fetchReviews();
                }}
                onDeleteSuccess={() => {
                  setShow(false);
                  onReviewDeleted(true);
                  fetchReviews();
                }}
              />
            </Container>
          </Dialog>,
          document.body
        )}
    </>
  );
};

OwnerResponse.propTypes = {
  reviewId: PropTypes.string,
};

OwnerResponse.defaultProps = {
  reviewId: undefined,
};

export default OwnerResponse;
