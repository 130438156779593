import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { gql, useMutation } from '@apollo/client';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Notification from 'ls-common-client/src/components/Notification';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Image from 'ls-common-client/src/components/Image';
import CheckBox from 'ls-common-client/src/components/CheckBox';
import Validator from 'ls-common-client/src/components/Validator';
import useForm from 'ls-common-client/src/hooks/useForm';
import { shallowEqual } from '../../../../../lib/util';
import FormRow from '../../../../common/FormRow';
import StarRatingInput from '../../../../common/StarRatingInput';
import ToggleInput from '../../../../common/ToggleInput';
import TextArea from '../../../../common/TextArea';
import Input from '../../../../common/Input';
import Button from '../../../../common/Button';
import {
  Comment,
  User,
  Envelope,
  Information,
  History,
} from '../../../../../images';
import { AppContext } from '../../../../../context';
import ExitDialog from './ExitDialog';
import DeleteDialog from './DeleteDialog';
import AdditionalInfo from './AdditionalInfo';
import HistoryPanel from './HistoryPanel';
import StateDropDown from './StateDropDown';
import OwnerResponse from './OwnerResponse';
import Assets from './Assets';

const StyledReviewForm = styled(Container)`
  min-height: 400px;
  width: 100%;
  max-width: 700px;
  .checkbox {
    width: 150px;
    max-width: 150px;
  }
`;

const updateQuery = gql`
  mutation UpdateReview($input: UpdateReviewInput!) {
    updateReview(input: $input) {
      review {
        id
        comment
        rating
        message
        displayName
        reviewState
        topReview
      }
    }
  }
`;

const deleteQuery = gql`
  mutation DeleteReview($id: String!) {
    deleteReview(id: $id) {
      success
    }
  }
`;

const StyledNoListingText = styled(Text)`
  color: ${props => props.theme.error.normal};
  font-weight: 600;
`;

const ReviewForm = ({ reviewId, onToggleShow, review, history }) => {
  const {
    setNotification,
    reviews: { fetchReviews },
  } = useContext(AppContext.Context);
  const [exitDialogShow, setExitDialogShow] = useState();
  const [deleteDialogShow, setDeleteDialogShow] = useState();
  const [hasReviewChanges, setHasReviewChanges] = useState();

  const {
    id = '',
    comment = '',
    rating = '',
    message = '',
    displayName = '',
    reviewState = '',
    createdAt = '',
    topReview = '',
    reviewResponse,
    reviewAssets,
    reviewContact: { email = '' } = {},
    profile,
  } = review || {};
  const { edges: assets = [] } = reviewAssets || {};
  const { id: reviewResponseId, deleted } = reviewResponse || {};
  const { displayName: listingName } = profile || {};

  const [updateReview, { loading: updating, error }] = useMutation(
    updateQuery,
    {
      onCompleted: () => {
        setNotification({
          show: true,
          data: {
            listingName,
          },
        });
        fetchReviews();
        onToggleShow();
      },
    }
  );

  const [deleteReview] = useMutation(deleteQuery, {
    onCompleted: () => {
      onToggleShow();
    },
  });

  const onDeleteReview = useCallback(() => {
    deleteReview({ variables: { id: reviewId } });
  }, [reviewId]);

  const defaults = {
    id,
    comment,
    rating,
    message,
    displayName,
    reviewState,
    topReview,
  };

  const submit = async input => {
    await updateReview({
      variables: {
        input,
      },
    });
  };

  const {
    setValue,
    values,
    validation: { dirty, valid, tooShort, valueMissing, patternMismatch },
    onSubmit,
    reset,
    valid: formValid,
    changed,
  } = useForm({
    defaults,
    onSubmit: async input => {
      try {
        await submit(input);
      } catch (err) {
        reset();
        throw err;
      }
    },
  });

  const reviewChanges = useMemo(() => {
    const commentMessages = {
      rating: () => 'Rating updated.',
      message: () => 'Message updated.',
      displayName: () => 'Name updated.',
      reviewState: () => `Status changed.`,
      topReview: () => 'Top review updated.',
    };
    if (
      !shallowEqual(
        {
          rating: values.rating,
          message: values.message,
          displayName: values.displayName,
          reviewState: values.reviewState,
          topReview: values.topReview,
        },
        {
          rating: defaults.rating,
          message: defaults.message,
          displayName: defaults.displayName,
          reviewState: defaults.reviewState,
          topReview: defaults.topReview,
        }
      )
    ) {
      return Object.keys(defaults)
        .reduce((agg, key) => {
          if (defaults[key] !== values[key] && commentMessages[key]) {
            return agg.concat(commentMessages[key](values[key]));
          }
          return agg;
        }, [])
        .join(' ');
    }
    return false;
  }, [values, defaults]);

  useEffect(() => {
    setValue({
      name: 'comment',
      value: reviewChanges || '',
    });
    setHasReviewChanges(reviewChanges || '');
    return () => {};
  }, [reviewChanges]);

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [reviewChanges]);

  return (
    <StyledReviewForm height="100%">
      <form onSubmit={onSubmit} noValidate>
        <Container
          display="flex"
          paddingTop="normal"
          paddingBottom="small"
          paddingLeft="small"
          paddingRight="medium"
          backgroundColor="background300"
        >
          <Container flex="1">
            {/* Business Name */}
            <Text
              fontSize="normal"
              marginBottom="small"
              marginLeft="normal"
              display="block"
            >
              {listingName || (
                <StyledNoListingText>
                  Listing No Longer Exists
                </StyledNoListingText>
              )}
            </Text>
            {/* Review Rating */}
            <StarRatingInput
              marginLeft="normal"
              marginBottom="small"
              size="30"
              name="rating"
              value={values.rating || 0}
              onClick={setValue}
              disabled={!listingName}
            />
            {/* Date Created */}
            <Text
              fontSize="small"
              marginBottom="small"
              marginLeft="normal"
              display="block"
              color="text400"
            >
              {moment(createdAt).format('MMMM DD YYYY - h:mma')}
            </Text>
          </Container>
          <Container
            minWidth="50px"
            alignItems="baseline"
            justifyContent="flex-end"
            display="flex"
          >
            <EmptyButton
              onClick={() => {
                if (reviewChanges) {
                  setExitDialogShow(true);
                } else {
                  onToggleShow();
                }
              }}
            >
              <Icon iconSize="xxlarge" className="ls-icon icon-generalxsmall" />
            </EmptyButton>
          </Container>
        </Container>

        {/* Reviewer Comment */}
        <Container
          paddingTop="normal"
          paddingBottom="small"
          paddingLeft="medium"
          paddingRight="medium"
        >
          <FormRow icon={<Image src={Comment} alt="comment" />}>
            <ToggleInput>
              <TextArea
                value={values.message || ''}
                onChange={setValue}
                name="message"
                maxLength="1000"
                pattern="^[a-zA-Z -]*$"
                placeholder="Comment"
                disabled={!listingName}
                style={{
                  minHeight: '150px',
                }}
              />
              <Text
                fontSize="xsmall"
                color="text300"
                display="block"
                textAlign="right"
                marginBottom="small"
              >
                {`${(values.message && values.message.length) || 0}/1000`}
              </Text>
            </ToggleInput>
          </FormRow>
          {/* Owner Response */}
          {!!reviewResponseId && !!reviewId && !deleted && (
            <OwnerResponse reviewId={reviewId} />
          )}
          {/* Reviewer Name */}
          <FormRow icon={<Image src={User} alt="user" />}>
            <ToggleInput>
              <Input
                value={values.displayName || ''}
                onChange={setValue}
                name="displayName"
                padding="small"
                minLength="2"
                pattern="^[a-zA-Z -]*$"
                placeholder="Name"
                required
                disabled={!listingName}
              />
              <Validator
                marginBottom="small"
                textAlign="right"
                show={!valid('displayName') && dirty('displayName')}
              >
                {tooShort('displayName') && (
                  <span>Please add atleast 2 characters</span>
                )}
                {valueMissing('displayName') && <span>Name is required</span>}
                {patternMismatch('displayName') && <span>Name is invalid</span>}
              </Validator>
            </ToggleInput>
          </FormRow>
          {!!listingName && (
            <>
              {/* Reviewer Email */}
              <FormRow icon={<Image src={Envelope} alt="envelope" />}>
                <Text
                  fontSize="normal"
                  marginBottom="small"
                  marginTop="small"
                  marginLeft="normal"
                  display="block"
                >
                  {email}
                </Text>
              </FormRow>
              {/* Reviewer Top Review, State */}
              <FormRow>
                <Container
                  display="flex"
                  justifyContent="flex-start"
                  marginTop="normal"
                  marginBottom="normal"
                  marginLeft="xsmall"
                  minHeight="43px"
                  alignItems="center"
                >
                  <CheckBox
                    className="checkbox"
                    label="Top Review"
                    name="topReview"
                    value={values.topReview}
                    defaultChecked={values.topReview}
                    onChange={setValue}
                  />
                  <StateDropDown
                    value={values.reviewState}
                    setValue={({ value }) => {
                      setValue({
                        name: 'reviewState',
                        value,
                      });
                    }}
                  />
                </Container>
              </FormRow>
              {/* Extra Review Info */}
              <FormRow icon={<Image src={Information} alt="information" />}>
                <AdditionalInfo review={review} />
              </FormRow>
            </>
          )}
          {/* Review History */}
          {history && !!history.length && (
            <FormRow
              marginBottom="20px"
              icon={
                <Icon
                  className="ls-icon icon-generalbook"
                  src={Information}
                  alt="information"
                  iconColor="normal"
                />
              }
            >
              <HistoryPanel history={history} />
            </FormRow>
          )}

          {assets && !!assets.length && <Assets assets={assets} />}
        </Container>

        {!!listingName && (
          <Container
            display="flex"
            flexDirection="column"
            padding="medium"
            backgroundColor="background300"
          >
            <FormRow icon={<Image src={History} alt="response" />}>
              <ToggleInput>
                <TextArea
                  value={values.comment || ''}
                  onChange={setValue}
                  name="comment"
                  minLength="0"
                  pattern="(.*)"
                  placeholder=""
                  required={!!hasReviewChanges}
                />
                <Text
                  fontSize="11px"
                  color="text300"
                  display="block"
                  textAlign="right"
                  marginBottom="small"
                >
                  {`${(values.comment && values.comment.length) || 0}/1000`}
                </Text>
                <Validator
                  marginBottom="small"
                  textAlign="right"
                  show={valueMissing('comment')}
                >
                  Comment is required
                </Validator>
              </ToggleInput>
            </FormRow>
            <FormRow display="flex" justifyContent="space-between">
              {error && (
                <Notification error padding>
                  <Icon
                    className="zmdi zmdi-alert-triangle"
                    size="medium"
                    marginRight
                  />
                  <Text color="normal">{error && error.message}</Text>
                </Notification>
              )}
              {/*
            <Button
              margin="0"
              marginLeft="auto"
              width="auto"
              type="button"
              fontWeight="normal"
              backgroundColor="#ef7f81"
              color="#ffffff"
              onClick={() => {
                setDeleteDialogShow(true);
              }}
            >
              Delete
            </Button> */}

              <Button
                secondary
                margin="0"
                marginLeft="auto"
                width="auto"
                type="submit"
                fontWeight="normal"
                disabled={!formValid() || !changed() || !reviewChanges}
              >
                {updating ? `Saving...` : `Save`}
              </Button>
            </FormRow>
          </Container>
        )}
      </form>
      <ExitDialog
        show={exitDialogShow}
        setShow={setExitDialogShow}
        onReviewShow={onToggleShow}
        onSubmit={() => {
          setExitDialogShow(false);
          submit(values);
        }}
        formValid={formValid}
      />
      <DeleteDialog
        show={deleteDialogShow}
        setShow={setDeleteDialogShow}
        onReviewShow={onToggleShow}
        onDeleteReview={onDeleteReview}
      />
    </StyledReviewForm>
  );
};

ReviewForm.propTypes = {
  review: PropTypes.shape({}),
  history: PropTypes.arrayOf(PropTypes.shape({})),
  onToggleShow: PropTypes.func,
  reviewId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

ReviewForm.defaultProps = {
  review: undefined,
  history: undefined,
  reviewId: undefined,
  onToggleShow: () => {},
};

export default ReviewForm;
