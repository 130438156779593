const defaults = require('./defaults');

module.exports = {
  ...defaults,
  hot: false,
  logger: {
    console: {
      level: 'error',
    },
  },
};
