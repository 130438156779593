import config from '../../config';

const { webUrlRoot } = config;

const filterProfileUrl = url => {
  if (!url) return '/';

  if (!webUrlRoot) return url;

  const filtered = url
    .replace('http://www.localsearch.com.au', webUrlRoot)
    .replace('https://www.localsearch.com.au', webUrlRoot);

  return filtered;
};

export default filterProfileUrl;
