import { util } from '../lib';

const { capitalize } = util;

const STATES = {
  PENDING: 'pending',
  APPROVED: 'approved',
  NOT_APPROVED: 'notApproved',
  INAPPROPRIATE: 'inappropriate',
  DISABLED_CLIENT: 'disabledClient',
  DISABLED_MANAGEMENT: 'disabledManagement',
  FLAGGED: 'flagged',
  INCORRECT_DETAILS: 'incorrectDetails',
};

const stateIcons = [
  {
    description: 'PENDING',
    icon: 'ls-icon icon-generalmore',
    color: 'orange',
  },
  {
    description: 'APPROVED',
    icon: 'ls-icon icon-generalticklarge',
    color: 'green',
  },
  {
    description: 'INAPPROPRIATE',
    icon: 'ls-icon icon-generalxlarge',
    color: 'red',
  },
  {
    description: 'DISABLED_CLIENT',
    icon: 'ls-icon icon-generalxlarge',
    color: 'red',
  },
  {
    description: 'FLAGGED',
    icon: 'ls-icon icon-generalflag',
    color: 'orange',
  },
  {
    description: 'NOT_APPROVED',
    icon: 'ls-icon icon-generalxlarge',
    color: 'red',
  },
  {
    description: 'INCORRECT_DETAILS',
    icon: 'ls-icon icon-generalxlarge',
    color: 'red',
  },
  {
    description: 'DISABLED_MANAGEMENT',
    icon: 'ls-icon icon-generalxlarge',
    color: 'red',
  },
];

const REVIEW_STATES = stateIcons.reduce((a, b) => {
  return a.concat({
    ...b,
    text: capitalize(b.description.replace(/_/g, ' ')),
    value: STATES[b.description],
  });
}, []);

export default REVIEW_STATES;
