import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import TableRow from 'ls-common-client/src/components/TableRow';
import TruncateMarkup from 'react-truncate-markup';
import TableCell from 'ls-common-client/src/components/TableCell';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import Image from 'ls-common-client/src/components/Image';
import StarRatingInput from '../../../common/StarRatingInput';
import StateIcon from '../../../common/StateIcon';
import { Response } from '../../../../images';

const StyledRow = styled(TableRow)`
  transform: scale(1);
  transition: all 0.3s ease;
  &:hover {
    cursor: pointer;
    box-shadow: ${props => props.theme.custom.shadow.table};
    transform: scale(1.01);
  }
  &.isDeleted {
    background: #f5eff0 !important;
    &:nth-child(odd) {
      background-color: #f3e1e4 !important;
    }
  }
`;

const StyledTableCell = styled(TableCell)`
  font-size: 14px;
  padding: 16px 15px;
`;

const StyledNoListingText = styled(Text)`
  color: ${props => props.theme.error.normal};
  font-weight: 600;
`;

const Review = ({ review, onClick }) => {
  const {
    createdAt,
    message,
    rating,
    deleted,
    reviewState,
    reviewResponse,
    reviewAssets,
    id,
    profile,
  } = review || {};
  const { edges: assets = [] } = reviewAssets || {};
  const { displayName, categories } = profile || {};
  const paid = useMemo(() => {
    return !!(
      categories &&
      categories.length &&
      categories.filter(({ package: pkg }) => pkg && pkg.name).length > 0
    );
  }, [categories]);

  return (
    <StyledRow
      className={deleted || !displayName ? 'isDeleted' : ''}
      onClick={() => onClick(id)}
    >
      <StyledTableCell>
        <Text
          display="block"
          style={{
            whiteSpace: 'pre',
          }}
        >
          {moment(createdAt).format('MMMM DD YYYY')}
        </Text>
        <Text>{moment(createdAt).format('h:mm a')}</Text>
      </StyledTableCell>
      <StyledTableCell>
        {assets && !!assets.length && (
          <Text
            display="flex"
            style={{
              whiteSpace: 'pre',
            }}
          >
            <Icon
              iconSize="small"
              className="ls-icon icon-categoryphotography"
            />
            {`${assets.length} image${assets.length > 1 ? 's' : ''}`}
          </Text>
        )}
      </StyledTableCell>
      <StyledTableCell>
        {!!message && (
          <TruncateMarkup ellipsis="..." line="2">
            <Text>{message}</Text>
          </TruncateMarkup>
        )}
        {reviewResponse && !!reviewResponse.id && !reviewResponse.deleted && (
          <Text fontSize="12px" color="text300" display="flex">
            <Image
              width="12px"
              src={Response}
              alt="response"
              margin="3px 6px 0 0"
            />
            With owners response
          </Text>
        )}
      </StyledTableCell>
      <StyledTableCell>
        <StarRatingInput value={rating} size="13" disabled />
      </StyledTableCell>
      <StyledTableCell>
        {displayName || (
          <StyledNoListingText>Listing No Longer Exists</StyledNoListingText>
        )}
      </StyledTableCell>
      <StyledTableCell>{paid && 'Yes'}</StyledTableCell>
      <StyledTableCell textAlign="center">
        <StateIcon status={reviewState} text />
      </StyledTableCell>
    </StyledRow>
  );
};

Review.propTypes = {
  review: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Review;
