import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Animation from 'ls-common-client/src/components/Animation';
import Container from 'ls-common-client/src/components/Container';
import { loader } from '../../../../../animations';
import useReview from '../../../../../hooks/useReview';
import ReviewForm from '.';

const ReviewWrapper = ({ onToggleShow, reviewId }) => {
  const { fetchReview, review, history, loading } = useReview();

  const getReview = useCallback(async () => {
    await fetchReview({
      variables: { reviewId },
    });
  }, [reviewId]);

  useEffect(() => {
    if (reviewId) {
      getReview();
    }
    return () => {};
  }, [reviewId]);

  return (
    <Container height="100%" minHeight="400px">
      {loading ? (
        <Container
          display="flex"
          justifyContent="center"
          width="100%"
          alignItems="start"
          minHeight="200px"
        >
          <Animation data={loader} width="180px" />
        </Container>
      ) : (
        review && (
          <Container>
            <ReviewForm
              reviewId={reviewId}
              review={review}
              history={history}
              onToggleShow={onToggleShow}
            />
          </Container>
        )
      )}
    </Container>
  );
};

ReviewWrapper.propTypes = {
  reviewId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onToggleShow: PropTypes.func,
};

ReviewWrapper.defaultProps = {
  reviewId: undefined,
  onToggleShow: () => {},
};

export default ReviewWrapper;
