import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

const userQuery = gql`
  {
    currentUser {
      id
      accountId
      displayName
      avatar
      suburbId
      userRoles {
        edges {
          node {
            roleName
          }
        }
      }
      account {
        firstName
        lastName
        phoneNumber
        email
        isEmailUpdatable
        isPasswordUpdatable
        identities {
          id
          connection
        }
      }
      suburb {
        name
        postcode
        state
      }
    }
  }
`;

const useUser = handleError => {
  const { data = {}, loading, refetch } = useQuery(userQuery);
  const { currentUser } = data || {};

  const isReviewsManager = useMemo(() => {
    if (currentUser) {
      const {
        userRoles: { edges: roles = [] },
      } = currentUser;

      const userRole = roles.find(
        ({ node }) => (node || {}).roleName === 'reviewManager'
      );

      if (!userRole) {
        handleError(
          {
            message:
              'You need a Reviews Manager role to access this application.',
          },
          false
        );
      }

      return true;
    }

    return null;
  }, [currentUser]);

  return {
    user: currentUser && { ...currentUser, isReviewsManager },
    loading,
    refetch,
  };
};

export default useUser;
