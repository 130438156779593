import React from 'react';
import PropTypes from 'prop-types';

function Star({ fill, stroke, strokeWidth, className, width, height }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="List-view---18-12---new-adverts"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="list-view---bigger-text-copy-2"
          transform="translate(-881.000000, -718.000000)"
          fill={fill}
        >
          <g id="Group-22" transform="translate(255.000000, 672.000000)">
            <g id="Group-Copy-7" transform="translate(586.000000, 46.000000)">
              <g id="Group-5-Copy" transform="translate(0.000000, 0.153846)">
                <polygon
                  id="Star-Copy-3"
                  points="45.5969733 9.62463073 42.0940416 11.1921138 42.5440028 7.46682088 39.9291108 4.71028576 43.7101338 3.9754112 45.5969733 0.704295704 47.4838128 3.9754112 51.2648358 4.71028576 48.6499438 7.46682088 49.0999049 11.1921138"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

Star.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Star.defaultProps = {
  fill: undefined,
  className: '',
  stroke: '',
  strokeWidth: 1,
  width: 12,
  height: 12,
};

export default Star;
