import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import Button from 'ls-common-client/src/components/Button';
import { Star10 } from '../../images';
import Input from './Input';

const StyledStarRatingInput = styled(Container)`
  display: flex;
  svg {
    fill: ${props => props.theme.shade.shade500};
  }
`;

const StyledStarButton = styled(Button)`
  padding: 0;
  border: none;
  box-shadow: none;
  background: none;
  margin: 0 0 0 2px;
  width: auto;
  min-width: 0;
  height: auto;
  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
  }
  &[disabled] {
    background: none;
    cursor: none;
  }
  ${props =>
    props.fullStar &&
    `
    svg {
      fill: ${props.theme.tertiary.tertiary100};
    }
  `}
`;

const StarRatingInput = memo(
  ({ value, onClick, size, name, disabled, ...props }) => {
    const starArrayFull = [1, 2, 3, 4, 5];
    const roundedRating = (Math.round(value * 2) / 2).toFixed(1);
    const ratingStarArray = starArrayFull.slice(0, roundedRating);
    return (
      <StyledStarRatingInput {...props}>
        {starArrayFull.map((num, index) => {
          const fullStar = ratingStarArray.length >= index + 1;
          return (
            <StyledStarButton
              key={num}
              type="button"
              disabled={disabled}
              fullStar={fullStar}
              onClick={() =>
                onClick({
                  target: {
                    name,
                    value: num,
                  },
                })
              }
            >
              <Star10 width={size} height={size} />
            </StyledStarButton>
          );
        })}
        <Input value={value} type="hidden" name={name} />
      </StyledStarRatingInput>
    );
  },
  (prevProps, nextProps) => prevProps.value === nextProps.value
);

StarRatingInput.propTypes = {
  value: PropTypes.number,
  name: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

StarRatingInput.defaultProps = {
  value: 0,
  name: '',
  size: '16px',
  onClick: () => {},
  disabled: false,
};

export default StarRatingInput;
