import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import Icon from 'ls-common-client/src/components/Icon';

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  color: ${props => props.theme.primary.normal};
  padding-top: ${props => props.theme.margin.xsmall};
  padding-bottom: ${props => props.theme.margin.small};
  cursor: pointer;
  i {
    line-height: 0.5;
    height: 0.3em;
    text-indent: -0.32em;
    width: 0.36em;
  }
`;

const StyledContainer = styled(Container)`
  height: 0;
  opacity: 0;
  transition: all 0.3s ease;
  overflow: hidden;
  ${props =>
    props.open &&
    `
    height: auto;
    opacity: 1;
  `}
`;

const Accordion = ({ text, children }) => {
  const [show, setShow] = useState(false);

  const onToggleShow = useCallback(() => {
    setShow(val => !val);
  }, []);

  const MemonizedAccordion = useMemo(
    () => (
      <Container>
        <StyledLink onClick={onToggleShow}>
          {show ? text.open : text.close}
          <Icon
            marginLeft
            className={`ls-icon ${
              show ? 'icon-generalcaretupmedium' : 'icon-generalcaretdownmedium'
            }`}
            iconSize="medium"
          />
        </StyledLink>
        <StyledContainer display="flex" flexDirection="column" open={show}>
          {children}
        </StyledContainer>
      </Container>
    ),
    [show]
  );
  return MemonizedAccordion;
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.shape({}).isRequired,
};

export default Accordion;
