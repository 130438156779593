import { gql, useLazyQuery } from '@apollo/client';

const query = gql`
  query Review($reviewId: String!) {
    review(id: $reviewId) {
      id
      listingId
      profile {
        id
        displayName
        vanityUrlPath
        categories {
          name
          package {
            name
          }
        }
      }
      comment
      rating
      message
      displayName
      suburbName
      market
      region
      state
      topReview
      reviewState
      updatedBy
      createdAt
      updatedAt
      reviewContact {
        email
      }
      reviewResponse {
        id
        deleted
      }
      reviewAssets {
        edges {
          node {
            id
            url
          }
        }
      }
      user {
        suburb {
          name
          state
          postcode
          market
        }
      }
    }
    reviewAudits(reviewId: $reviewId) {
      edges {
        node {
          id
          reviewId
          listingId
          comment
          rating
          message
          displayName
          suburbName
          market
          region
          state
          topReview
          reviewState
          updatedBy
          createdAt
        }
      }
    }
  }
`;

const useReview = () => {
  const [fetchReview, { data, loading, error }] = useLazyQuery(query, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });

  const { review, reviewAudits } = data || {};
  const { edges: history = [] } = reviewAudits || [];

  return {
    review,
    fetchReview,
    history,
    loading,
    error,
  };
};

export default useReview;
