import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Image from 'ls-common-client/src/components/Image';
import UserMenu from 'ls-common-client/src/components/UserMenu';
import Text from 'ls-common-client/src/components/Text';
import { localsearch } from '../../images';
import { AppContext } from '../../context';
import { auth, imageResizer } from '../../services';
import HelpMenu from './HelpMenu';

const Header = ({ children }) => {
  const {
    user: { user },
  } = useContext(AppContext.Context);
  const { displayName } = user;

  return (
    <Container
      display="flex"
      flexDirection="row"
      justifyContent="center"
      backgroundColor="background800"
      height="195px"
      width="100%"
    >
      <Container
        maxWidth="1110px"
        width="100%"
        justifyContent="space-between"
        display="flex"
        flexDirection="column"
      >
        <Container
          width="100%"
          padding="30px 0 0"
          justifyContent="space-between"
          display="flex"
          flexDirection="row"
        >
          <Container>
            <Link to="/">
              <Image width="162px" src={localsearch} alt="Localsearch" />
            </Link>
          </Container>
          <Container display="flex">
            <Container>
              <HelpMenu />
            </Container>
            <UserMenu
              user={user}
              auth={auth}
              imageResizer={imageResizer}
              marginLeft
            />
            <Text
              marginLeft="10px"
              display="flex"
              alignItems="center"
              color="#fff"
            >
              {displayName}
            </Text>
          </Container>
        </Container>
        {!!children && children}
      </Container>
    </Container>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

Header.defaultProps = {
  children: null,
};

export default Header;
