import React from 'react';
import Container from 'ls-common-client/src/components/Container';
import Header from '../../common/Header';
import Reviews from './Reviews';
import FilterTabs from './FilterTabs';
import SearchFilters from './SearchFilters';

const Home = () => {
  return (
    <Container>
      <Header>
        <Container
          width="100%"
          justifyContent="space-between"
          display="flex"
          flexDirection="row"
        >
          <FilterTabs />
        </Container>
      </Header>
      <SearchFilters />
      <Reviews />
    </Container>
  );
};

export default Home;
