import React from 'react';
import PropTypes from 'prop-types';

function Star07({ fill, className }) {
  return (
    <svg
      className={className}
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 19"
    >
      <polygon
        points="3.82 19 10 16.16 14 18 14 6.06 13.33 5.93 10 0 6.67 5.93 0 7.26 4.61 12.25 3.82 19"
        fill={fill}
      />
    </svg>
  );
}

Star07.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};

Star07.defaultProps = {
  fill: undefined,
  className: '',
};

export default Star07;
