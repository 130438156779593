import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import useMedia from 'ls-common-client/src/hooks/useMedia';
import AlertDialog from 'ls-common-client/src/components/AlertDialog';
import Splash from 'ls-common-client/src/components/Splash';
import NotificationSlider from 'ls-common-client/src/components/NotificationSlider';
import useUser from '../hooks/useUser';
import useReviews from '../hooks/useReviews';

const Context = createContext();

const Provider = ({ children }) => {
  const [error, setError] = useState();
  const [notification, setNotification] = useState();

  const { show, data } = notification || {};
  const { listingName } = data || {};

  const handleError = (e, throwError = true) => {
    setError(e);
    if (throwError) {
      throw e;
    }
  };

  const theme = useContext(ThemeContext);
  const media = useMedia(theme.mediaQueries);
  const user = useUser(handleError);
  const reviews = useReviews(handleError);

  const { mobile } = media || {};

  return (
    <Context.Provider
      value={{
        user,
        media,
        handleError,
        error,
        reviews,
        notification,
        setNotification,
      }}
    >
      {children}
      <AlertDialog
        show={!!error}
        heading="Oops, something went wrong."
        text={`${error && error.type ? `${error.type}: ` : ''}${
          error ? error.message : ''
        }`}
        buttons={[
          {
            children: 'OK',
            type: 'button',
            props: {
              secondary: true,
              rounded: true,
              onClick: () => setError(false),
            },
          },
        ]}
      />
      <NotificationSlider
        icon={<Splash />}
        heading={`${listingName} Updated Successfully`}
        duration={5000}
        show={!!show}
        mode={mobile ? 'mobile' : 'desktop'}
        onClose={() => setNotification({ show: false })}
      />
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
};

Provider.defaultProps = {
  children: null,
};

export { Provider, Context };
