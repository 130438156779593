import { relayStylePagination } from '@apollo/client/utilities';

const typePolicies = {
  Query: {
    fields: {
      reviews: relayStylePagination(),
    },
  },
};

export default typePolicies;
