import React from 'react';
import PropTypes from 'prop-types';

function Star01({ fill, className }) {
  return (
    <svg
      className={className}
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 19"
    >
      <polygon points="2 6.86 0 7.26 2 9.42 2 6.86" fill={fill} />
    </svg>
  );
}

Star01.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};

Star01.defaultProps = {
  fill: undefined,
  className: '',
};

export default Star01;
