import React from 'react';
import styled from 'styled-components';
import InputWrapper from 'ls-common-client/src/components/Input';

const StyleInput = styled(InputWrapper)`
  font-size: 16px;
`;

const Input = ({ ...props }) => {
  return <StyleInput {...props} autoComplete="off" />;
};

export default Input;
