import React from 'react';
import styled from 'styled-components';
import ButtonWrapper from 'ls-common-client/src/components/Button';

const StyleButton = styled(ButtonWrapper)`
  font-size: 16px;
`;

// eslint-disable-next-line react/prop-types
const Button = ({ children, ...props }) => {
  return (
    <StyleButton {...props} rounded>
      {children}
    </StyleButton>
  );
};

export default Button;
