import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'ls-common-client/src/components/Icon';

// eslint-disable-next-line react/prop-types
const Element = ({ elementTag, children, ...props }) => {
  return createElement(elementTag, props, children);
};

const StyledElement = styled(Element)`
  border-radius: 100%;
  border: solid 1px ${props => props.theme.background.background700};
  background-color: ${props => props.theme.shade.shade200};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  &.green {
    border: solid 1px ${props => props.theme.secondary.normal};
    color: ${props => props.theme.secondary.normal};
    background-color: ${props => props.theme.custom.colors.lightGreen};
  }
  &.red {
    border: solid 1px ${props => props.theme.error.normal};
    color: ${props => props.theme.error.normal};
    background-color: ${props => props.theme.custom.colors.lightRed};
  }
  &.orange {
    border: solid 1px ${props => props.theme.custom.colors.orange};
    color: ${props => props.theme.custom.colors.orange};
    background-color: ${props => props.theme.custom.colors.lightOrange};
  }
  &.grey {
    :hover {
      border-color: ${props => props.theme.primary.normal};
      i {
        color: ${props => props.theme.primary.normal};
      }
    }
  }
  i {
    height: auto;
    width: auto;
    text-indent: initial;
    padding: 0;
    margin: 0;
    line-height: 1em;
    position: relative;
    top: 2px;
  }
`;

const RoundIcon = ({ component, iconProps, size, ...props }) => {
  return (
    <StyledElement
      elementTag={component}
      {...props}
      style={{
        width: size,
        height: size,
      }}
    >
      <Icon {...iconProps} />
    </StyledElement>
  );
};

RoundIcon.propTypes = {
  component: PropTypes.string,
  size: PropTypes.string,
  iconProps: PropTypes.shape({}),
};

RoundIcon.defaultProps = {
  component: 'div',
  size: '31px',
  iconProps: {},
};

export default RoundIcon;
