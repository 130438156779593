import React from 'react';
import PropTypes from 'prop-types';

function Star02({ fill, className }) {
  return (
    <svg
      className={className}
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 19"
    >
      <polygon points="4 6.46 0 7.26 4 11.59 4 6.46" fill={fill} />
    </svg>
  );
}

Star02.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};

Star02.defaultProps = {
  fill: undefined,
  className: '',
};

export default Star02;
