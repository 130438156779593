import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Container from 'ls-common-client/src/components/Container';
import Table from 'ls-common-client/src/components/Table';
import TableRow from 'ls-common-client/src/components/TableRow';
import TableHeaderCell from 'ls-common-client/src/components/TableHeaderCell';
import TableCell from 'ls-common-client/src/components/TableCell';

const HistoryPanel = memo(
  ({ history }) => {
    if (history && history.length) {
      return (
        <Container
          maxHeight="130px"
          height="100%"
          overflow="auto"
          borderColor="border300"
          border="1px solid"
        >
          <Table>
            <thead>
              <TableRow>
                <TableHeaderCell fontWeight="600" padding="10px">
                  Date Changed
                </TableHeaderCell>
                <TableHeaderCell fontWeight="600" padding="10px">
                  User
                </TableHeaderCell>
                <TableHeaderCell fontWeight="600" padding="10px">
                  Status
                </TableHeaderCell>
                <TableHeaderCell fontWeight="600" padding="10px">
                  Comments
                </TableHeaderCell>
              </TableRow>
            </thead>
            <tbody>
              {history.map(
                ({
                  node: {
                    id,
                    createdAt,
                    updatedBy,
                    reviewState,
                    comment,
                    deleted,
                  },
                }) => (
                  <TableRow key={id}>
                    <TableCell padding="10px">
                      {moment(createdAt).format('MMMM DD YYYY h:mm a')}
                    </TableCell>
                    <TableCell padding="10px">{updatedBy}</TableCell>
                    <TableCell padding="10px">
                      {deleted ? 'deleted' : reviewState}
                    </TableCell>
                    <TableCell padding="10px">{comment}</TableCell>
                  </TableRow>
                )
              )}
            </tbody>
          </Table>
        </Container>
      );
    }

    return null;
  },
  (prevProps, nextProps) => prevProps.history === nextProps.history
);

HistoryPanel.propTypes = {
  history: PropTypes.arrayOf(PropTypes.shape({})),
};

HistoryPanel.defaultProps = {
  history: [],
};

export default HistoryPanel;
