import { useState, useEffect, useMemo, useCallback } from 'react';
import { gql, useQuery } from '@apollo/client';
import { DEFAULT_FILTERS } from '../constants';
import { util, getNodes } from '../lib';

const query = gql`
  query Reviews(
    $listingId: String
    $email: NonEmptyString
    $reviewState: ReviewStateRegex
    $startDate: DateTime
    $endDate: DateTime
    $after: String
    $sort: ReviewSortRegex
    $sortDirection: SortDirectionRegex
  ) {
    reviews(
      listingId: $listingId
      email: $email
      reviewState: $reviewState
      startDate: $startDate
      endDate: $endDate
      after: $after
      sort: $sort
      sortDirection: $sortDirection
      first: 15
    ) {
      edges {
        node {
          id
          profile {
            displayName
            categories {
              name
              package {
                name
              }
            }
          }
          rating
          message
          reviewState
          createdAt
          reviewResponse {
            id
            message
            deleted
          }
          reviewAssets {
            edges {
              node {
                id
              }
            }
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const useReviews = handleError => {
  const defaults = DEFAULT_FILTERS;
  const [reviewModalId, setReviewModalId] = useState();
  const [filters, setFilters] = useState(defaults);
  const [resetLoading, setResetLoading] = useState();
  const [activeTab, setActiveTab] = useState('pending');
  const [moreLoading, setMoreLoading] = useState();
  const { data, refetch, loading, error, fetchMore } = useQuery(query, {
    variables: util.removeNullObjectKeys(defaults || {}),
    errorPolicy: 'all',
  });

  const { reviews: reviewsData } = data || {};
  const { edges = [], pageInfo = {} } = reviewsData || {};

  const formatFilters = (obj = {}) => {
    return Object.keys(obj).reduce((acc, k) => {
      if (obj[k] === null) {
        acc[k] = undefined;
      } else {
        acc[k] = obj[k];
      }
      return acc;
    }, {});
  };

  const fetchMoreReviews = async params =>
    fetchMore({
      variables: formatFilters(params),
    });

  const reviews = useMemo(() => getNodes(edges || []), [edges]);

  useEffect(() => {
    if (error) {
      const { graphQLErrors = [], message = '' } = error;
      const hasMissingListingError =
        (graphQLErrors || []).reduce((agg, item) => {
          const { path = [] } = item || {};
          const lastItem = path[path.length - 1];
          return lastItem === 'listing' ? true : agg;
        }, '') && message === 'Resource does not exist';
      if (!hasMissingListingError)
        handleError(
          {
            type: `${error.graphQLErrors ? ' GraphQL Error' : ''}${
              error.networkError ? ' Network Error' : ''
            }`,
            message: error.message,
          },
          false
        );
    }
    return () => {};
  }, [error]);

  const fetchReviews = useCallback(
    async (params, reset) => {
      const newFilters = {
        ...(reset ? DEFAULT_FILTERS : filters),
        ...(params || {}),
      };
      await setFilters(newFilters);
      await refetch(formatFilters(newFilters));
    },
    [filters]
  );

  return {
    filters,
    reviews,
    pageInfo,
    loading,
    moreLoading,
    resetLoading,
    error,
    activeTab,
    setActiveTab,
    setResetLoading,
    setMoreLoading,
    reviewModalId,
    setReviewModalId,
    fetchMore: async params => {
      setMoreLoading(true);
      await fetchMoreReviews(params || {});
      setMoreLoading(false);
    },
    fetchReviews: async (params, reset) => {
      setResetLoading(true);
      await fetchReviews(params, reset);
      setResetLoading(false);
    },
  };
};

export default useReviews;
