import React from 'react';
import PropTypes from 'prop-types';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Dialog from 'ls-common-client/src/components/Dialog';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import { Button } from '../../../../common';

const DeleteDialog = ({ show, setShow, onReviewShow, onDeleteReview }) => {
  return (
    <Dialog
      show={show}
      header={<div />}
      padding="medium"
      minWidth="447px"
      maxWidth="447px"
    >
      <Container
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="150px"
      >
        <Container
          display="flex"
          justifyContent="space-between"
          marginBottom="normal"
        >
          <Text
            width="120px"
            flex="1 0 auto"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            fontSize="22px"
            fontWeight="600"
          >
            Delete review
          </Text>
          <EmptyButton
            onClick={() => {
              setShow(false);
            }}
          >
            <Icon iconSize="xxlarge" className="ls-icon icon-generalxsmall" />
          </EmptyButton>
        </Container>
        <Container flex="1">
          <Text fontSize="normal">
            Are you sure you want to delete this review?
          </Text>
        </Container>
        <Container display="flex" justifyContent="space-between">
          <Button
            margin="0"
            width="auto"
            fontWeight="normal"
            border="1px solid #4a4a4a"
            flex="1 0 30%"
            maxWidth="30%"
            color="normal"
            type="button"
            onClick={() => {
              setShow(false);
              onReviewShow(false);
            }}
          >
            No
          </Button>
          <Button
            margin="0"
            width="auto"
            fontWeight="normal"
            flex="1 0 60%"
            maxWidth="60%"
            type="button"
            backgroundColor="#ef7f81"
            color="#ffffff"
            onClick={() => onDeleteReview()}
          >
            Yes, delete this review
          </Button>
        </Container>
      </Container>
    </Dialog>
  );
};

DeleteDialog.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  onReviewShow: PropTypes.func,
  onDeleteReview: PropTypes.func,
};

DeleteDialog.defaultProps = {
  show: undefined,
  setShow: () => {},
  onReviewShow: () => {},
  onDeleteReview: () => {},
};

export default DeleteDialog;
