import React, { useState } from 'react';
import Container from 'ls-common-client/src/components/Container';
import Icon from 'ls-common-client/src/components/Icon';
import Menu from 'ls-common-client/src/components/Menu';
import Popup from 'ls-common-client/src/components/Popup';
import ClickOutside from 'ls-common-client/src/components/ClickOutside';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';

const data = [
  {
    href: 'http://support.localsearch.com.au/',
    text: 'Submit a ticket',
    target: '_blank',
    icon: 'ls-icon icon-categoryartsandentertainment',
  },
  {
    text: 'Call NAU at 1300 123 456',
    icon: 'ls-icon icon-generalcalllarge',
  },
];

const HelpMenu = () => {
  const [show, setShow] = useState(false);

  return (
    <ClickOutside onClickOutside={() => setShow(false)} marginLeft>
      <Container>
        <EmptyButton
          onClick={() => {
            setShow(!show);
          }}
        >
          <Icon
            className="ls-icon icon-generalabout"
            iconColor="light"
            iconSize="32px"
            marginTop="4px"
          />
        </EmptyButton>
        <Popup
          show={show}
          left="auto"
          right="0px"
          marginTop="small"
          width="250px"
        >
          <Menu data={data} />
        </Popup>
      </Container>
    </ClickOutside>
  );
};

export default HelpMenu;
