import React from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';

const FormRow = ({ icon, children, ...props }) => {
  return (
    <Container display="flex" width="100%">
      <Container
        fontSize="32px"
        maxWidth="32px"
        width="32px"
        paddingTop="14px"
        display="flex"
        alignItems="flex-start"
        alig
      >
        {icon}
      </Container>
      <Container flex="1" {...props}>
        {children}
      </Container>
    </Container>
  );
};

FormRow.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
};

FormRow.defaultProps = {
  icon: undefined,
};

export default FormRow;
