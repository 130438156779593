const defaults = require('./defaults');

const { port, auth } = defaults;

module.exports = {
  ...defaults,
  favicon: false,
  imageResizeRoot: 'https://d7dgpygyd9llm.cloudfront.net',
  api: 'https://preprod.services.lsapis.com.au',
  webUrlRoot: 'https://preprod.ls-www.localsearch.cloud',
  token: {
    ...defaults.token,
    cookie: {
      domain: 'localhost',
    },
  },
  auth: {
    ...auth,
    domain: 'staging.auth.localsearch.cloud',
    audience: 'https://performance.lsapis.com.au',
    clientID: 'bwZNpYFWRoGopK701KhaqilvZSYA5yOp',
    redirectUri: `http://localhost:${port}`,
  },
};
