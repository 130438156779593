import React, { useContext } from 'react';
import Dialog from 'ls-common-client/src/components/Dialog';
import { AppContext } from '../../../../context';
import ReviewWrapper from './ReviewForm/ReviewWrapper';

const ReviewDialog = () => {
  const {
    reviews: { setReviewModalId, reviewModalId },
    media: { mobile },
  } = useContext(AppContext.Context);

  return (
    <Dialog
      show={!!reviewModalId}
      header={<div />}
      maxWidth="700px"
      maxHeight={mobile ? '100vh' : 'calc(100vh - 40px)'}
      height="100%"
      width="100%"
      minWidth={mobile ? '100%' : '700px'}
      padding="0"
      background="background100"
      overflow="auto"
    >
      <ReviewWrapper
        reviewId={reviewModalId}
        onToggleShow={() => setReviewModalId(false)}
      />
    </Dialog>
  );
};

export default ReviewDialog;
