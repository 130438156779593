/* eslint-disable react/prop-types */

import React from 'react';
import Container from 'ls-common-client/src/components/Container';
import Icon from 'ls-common-client/src/components/Icon';
import styled from 'styled-components';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

const StyledContainer = styled(Container)`
  .react-calendar {
    width: 350px;
    max-width: 100%;
    border-radius: 4px;
    border: none;
    font-family: ${props => props.theme.font.circular};
    line-height: 1.125em;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
    font-family: ${props => props.theme.font.circular};
    font-size: 12px;
  }
  .react-calendar abbr[title] {
    text-decoration: none;
  }
  .react-calendar button.react-calendar__navigation__arrow {
    font-size: 14px;
  }
  .react-calendar button:last-child {
    margin: 0;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
  }
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: #2962ff;
    color: #ffffff;
  }
  .react-calendar__tile--now {
    background: ${props => props.theme.success.success200};
    color: #ffffff;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: ${props => props.theme.primary.primary300};
    color: #ffffff;
  }
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: ${props => props.theme.primary.primary200};
    color: #4a4a4a;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: ${props => props.theme.primary.primary300};
    color: #ffffff;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }

  .react-daterange-picker {
    display: inline-flex;
    position: relative;
    input:hover:not([readonly]),
    input:focus:not([readonly]) {
      border: none !important;
      outline: none !important;
    }
  }
  .react-daterange-picker,
  .react-daterange-picker *,
  .react-daterange-picker *:before,
  .react-daterange-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-daterange-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
  }
  .react-daterange-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    border: 1px solid #d1d1d1;
    padding-left: 12px;
  }
  .react-daterange-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
    height: 100%;
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;
  }
  .react-daterange-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
  }
  .react-daterange-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font-family: ${props => props.theme.font.circular};
    font-size: ${props => props.theme.fontSize.normal};
    box-sizing: content-box;
    -moz-appearance: textfield;
  }
  .react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .react-daterange-picker__inputGroup__input:invalid {
    background: none;
  }
  .react-daterange-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }
  .react-daterange-picker__button {
    font: normal normal normal 14px/1 'Localsearch-Icon-Font';
    border: 0;
    background: transparent;
    padding: 4px 6px;
  }
  &.hasValue {
    .react-daterange-picker__range-divider,
    .react-date-picker__inputGroup__divider {
      color: #4a4a4a;
    }
  }
  .react-daterange-picker__range-divider {
    color: #d1d1d1;
  }
  .react-daterange-picker__button i {
    font-family: ${props => props.theme.font.localsearch};
    font-size: 38px;
    color: #4a4a4a;
    top: 8px;
    position: relative;
    right: 9px;
  }
  .react-daterange-picker__button:enabled {
    cursor: pointer;
  }
  .react-daterange-picker__button:enabled:hover
    .react-daterange-picker__button__icon,
  .react-daterange-picker__button:enabled:focus
    .react-daterange-picker__button__icon {
    stroke: #0078d7;
  }
  .react-daterange-picker__button:disabled
    .react-daterange-picker__button__icon {
    stroke: #6d6d6d;
  }
  .react-daterange-picker__button svg {
    display: inherit;
  }
  .react-daterange-picker__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    box-shadow: none !important;
  }
  .react-daterange-picker__calendar--closed {
    display: none;
  }

  .react-daterange-picker * {
  }
  .react-daterange-picker--closed {
    border-radius: 4px;
  }
  .react-daterange-picker--closed .react-daterange-picker__wrapper {
    border-radius: 4px;
  }
  .react-daterange-picker--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .react-daterange-picker--open .react-daterange-picker__wrapper {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .react-daterange-picker__wrapper {
    background: white;
    border-radius: 4px;
    font-size: 1.25em;
  }
  .react-daterange-picker__inputGroup {
    min-width: 0;
    flex-grow: 1;
    display: flex;
    padding: 2px;
    align-items: baseline;
  }
  .react-date-picker__inputGroup__divider {
    font-family: ${props => props.theme.font.circular};
    font-size: ${props => props.theme.fontSize.normal};
    color: #d1d1d1;
  }
  .react-daterange-picker__inputGroup__divider {
    padding: 1px;
  }
  .react-daterange-picker__inputGroup__input {
    -moz-appearance: textfield;
    font-family: ${props => props.theme.font.circular};
    font-size: ${props => props.theme.fontSize.normal};
    color: #4a4a4a;
  }
  .react-daterange-picker__inputGroup__leadingZero {
    font-family: ${props => props.theme.font.circular};
    font-size: ${props => props.theme.fontSize.normal};
    color: #4a4a4a;
  }
  .react-daterange-picker__inputGroup__input::placeholder {
    color: ${props => props.theme.text.text200};
  }
  .react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .react-daterange-picker__inputGroup__input:invalid {
    background: none;
  }
  .react-daterange-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }

  .react-daterange-picker__clock {
    border-radius: 4px;
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
    font-family: ${props => props.theme.font.circular};
    width: 220px;
    height: 220px;
    padding: 20px;
    border: 0;
    border-top-left-radius: 0;
  }
  .react-daterange-picker__clock .react-clock__second-hand {
    transition: transform cubic-bezier(0.68, 0, 0.27, 1.55) 0.2s;
  }
  .react-daterange-picker__clock .react-clock__second-hand__body:before {
    width: 5px;
    height: 5px;
  }
  .react-daterange-picker__clock .react-clock__second-hand__body:after {
    width: 15px;
    height: 15px;
  }
  .react-daterange-picker--open {
    border-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .react-daterange-picker--open .react-daterange-picker__wrapper {
    border-radius: 4px;
    border-bottom-left-radius: 0;
  }
`;

const DatePicker = ({ setValue, ...props }) => {
  return (
    <StyledContainer
      // eslint-disable-next-line react/destructuring-assignment
      className={`${props.value ? 'hasValue' : 'emptyValue'}`}
    >
      <DateRangePicker
        onChange={setValue}
        calendarIcon={null}
        {...props}
        clearIcon={
          <Icon
            className="ls-icon icon-generalxsmall"
            iconSize="31px"
            iconColor="dark"
          />
        }
      />
    </StyledContainer>
  );
};

export default DatePicker;
