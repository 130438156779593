const defaults = require('./defaults');

const { auth } = defaults;

module.exports = {
  ...defaults,
  hot: false,
  imageResizeRoot: 'https://d7dgpygyd9llm.cloudfront.net',
  api: 'https://preprod.services.lsapis.com.au',
  webUrlRoot: 'https://preprod.ls-www.localsearch.cloud',
  auth: {
    ...auth,
    domain: 'preprod.auth.localsearch.cloud',
    audience: 'https://performance.lsapis.com.au',
    clientID: 'bwZNpYFWRoGopK701KhaqilvZSYA5yOp',
    redirectUri: 'https://preprod.reviews.lsapps.com.au',
  },
  deploy: {
    domain: 'preprod.reviews.lsapps.com.au',
    zoneID: 'Z06088083UOPUBT2013I1',
    certificateArn:
      'arn:aws:acm:us-east-1:855737094537:certificate/ef2aec4e-971d-4cbc-96cf-1215b4ef037e',
  },
};
