const { NODE_ENV: env = 'local', PORT: port = 8080 } = process.env;

module.exports = {
  env,
  port,
  debug: true,
  mode: 'development',
  sourceMap: true,
  hot: true,
  favicon: true,
  title: 'Reviews Manager',
  auth: {
    responseType: 'token id_token',
    scope: 'openid',
  },
  logger: {
    console: {
      level: 'debug',
    },
  },
  token: {
    tolerance: 5 * 60 * 1000, // 5 minutes
    cookie: { domain: '.lsapps.com.au' },
  },
  imageResizeRoot: 'https://d1zmvcyjeoeklt.cloudfront.net',
  webUrlRoot: 'https://development.ls-www.localsearch.cloud',
  api: 'https://development.services.lsapis.com.au',
};
