const local = require('./local');
const preprod = require('./preprod');
const development = require('./development');
const test = require('./test');
const staging = require('./staging');
const production = require('./production');

const config =
  {
    local,
    preprod,
    development,
    test,
    staging,
    production,
  }[process.env.NODE_ENV] || local;

module.exports = config;
