import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextAreaWrapper from 'ls-common-client/src/components/TextArea';

const StyleTextArea = styled(TextAreaWrapper)`
  font-size: 16px;
`;

const TextArea = ({ children, ...props }) => {
  return <StyleTextArea {...props}>{children}</StyleTextArea>;
};

TextArea.propTypes = {
  children: PropTypes.node,
};

TextArea.defaultProps = {
  children: '',
};

export default TextArea;
