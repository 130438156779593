import React from 'react';
import PropTypes from 'prop-types';
import Label from 'ls-common-client/src/components/Label';
import styled from 'styled-components';

const StyledLabel = styled(Label)`
  position: relative;
  input {
    border-color: ${props => props.theme.border.normal} !important;
  }
  &.withIcon {
    .ls-icon {
      position: absolute;
      left: 16px;
      top: 15px;
    }
    input {
      flex: 1;
      padding-left: 40px;
    }
  }
`;

const SearchLabel = ({ children, ...props }) => {
  return <StyledLabel {...props}>{children}</StyledLabel>;
};

SearchLabel.propTypes = {
  children: PropTypes.node,
};

SearchLabel.defaultProps = {
  children: undefined,
};

export default SearchLabel;
