const capitalize = str => {
  if (!str) return '';

  return str.replace(
    /\w\S*/g,
    txt => `${txt.charAt(0).toUpperCase()}${txt.substr(1).toLowerCase()}`
  );
};

const deepValue = (obj, path) =>
  path
    .replace(/\[|\]\.?/g, '.')
    .split('.')
    .filter(s => s)
    .reduce((acc, val) => acc && acc[val], obj);

const shallowEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
};

const removeNullObjectKeys = obj => {
  const cleanObj = obj;
  Object.keys(cleanObj).forEach(
    key =>
      (cleanObj[key] == null || cleanObj[key] === '') && delete cleanObj[key]
  );
  return cleanObj;
};

export { capitalize, deepValue, shallowEqual, removeNullObjectKeys };
