import React, { useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AppContext } from '../context';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import Intro from './Intro';

const Layout = () => {
  const {
    user: { user },
    error,
  } = useContext(AppContext.Context);
  const [loader, setLoader] = useState(true);

  const { isReviewsManager } = user || {};

  useEffect(() => {
    if (isReviewsManager || error) {
      setTimeout(() => {
        setLoader(false);
      }, 600);
    }
    return () => {};
  }, [isReviewsManager, error]);

  return (
    <Router>
      {loader && <Intro />}
      {!!user && !!isReviewsManager && (
        <>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </>
      )}
    </Router>
  );
};

export default Layout;
