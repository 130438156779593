import REVIEW_STATES from './REVIEW_STATES';
import ROLES from './ROLES';

const DEFAULT_FILTERS = {
  listingId: null,
  email: null,
  startDate: null,
  endDate: null,
  after: null,
  reviewState: 'pending',
  // deleted: false,
  sort: 'date',
  sortDirection: 'desc',
};

export { REVIEW_STATES, ROLES, DEFAULT_FILTERS };
