import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import DropDown from 'ls-common-client/src/components/DropDown';
import Text from 'ls-common-client/src/components/Text';
import Icon from 'ls-common-client/src/components/Icon';
import { REVIEW_STATES } from '../../../../../constants';
import StateIcon from '../../../../common/StateIcon';

const StyledDropDown = styled(DropDown)`
  > a,
  > button {
    border-color: transparent;
    border-radius: 80px;
    &:hover {
      border-color: ${props => props.theme.border.normal};
    }
    > i {
      display: none;
    }
  }
  > div {
    &:before {
      border-top-left-radius: 80px;
      border-top-right-radius: 80px;
    }
    &.show {
      box-shadow: none;
      border-top: 1px solid ${props => props.theme.shade.shade400};
      margin-top: 0;
      transition: all 0.3s ease;
      &:before {
        content: '';
        position: absolute;
        border-radius: 4px;
        border: 1px solid ${props => props.theme.border.normal};
        width: 100%;
        top: -44px;
        height: calc(100% + 44px);
        box-shadow: ${props => props.theme.custom.shadow.table};
      }
    }
  }
`;

const StyledStateDropDown = styled(Container)`
  display: flex;
  align-items: center;
  min-width: 200px;
  max-width: 100%;
  div {
    margin-right: 8px;
    > div {
      position: relative;
      top: 2px;
    }
  }
`;

const StyledStateDropDownItem = styled(Container)`
  display: flex;
  align-items: center;
  padding: 6px 12px 8px 17px;
  justify-content: flex-start;
  div {
    margin-right: 8px;
    > div {
      position: relative;
      top: 2px;
    }
  }
  label {
    font-size: 15px;
  }
`;

const RadioIcon = styled(Text)`
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: solid 1px #d1d1d1;
  background-color: #f8f9fb;
  margin-right: 10px;
  position: relative;
  &.selected {
    background-color: #e9efff;
    i {
      position: absolute;
      width: 25px;
      height: 25px;
      left: -4px;
      top: -2px;
      font-size: 32px;
    }
  }
`;

const StateDropDown = memo(
  ({ value, setValue }) => {
    const data = REVIEW_STATES.reduce(
      (a, b) => [
        ...a,
        {
          text: (
            <StyledStateDropDown>
              <StateIcon status={b.value} />
              {b.text}
            </StyledStateDropDown>
          ),
          selected: b.value === value,
          value: b.value,
          children: (
            <StyledStateDropDownItem>
              <RadioIcon className={b.value === value ? 'selected' : ''}>
                {b.value === value && (
                  <Icon className="ls-icon icon-generalticklarge" />
                )}
              </RadioIcon>
              {b.text}
            </StyledStateDropDownItem>
          ),
        },
      ],
      []
    );

    return (
      <Container display="flex">
        <StyledDropDown
          data={data}
          onSelected={item => {
            setValue(item);
          }}
        />
      </Container>
    );
  },
  (prevProps, nextProps) => prevProps.value === nextProps.value
);

StateDropDown.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default StateDropDown;
