import React from 'react';
import PropTypes from 'prop-types';

function Star10({ fill, className, width, height }) {
  return (
    <svg
      className={className}
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 19"
      width={width}
      height={height}
    >
      <polygon
        points="10 16.16 3.82 19 4.61 12.25 0 7.26 6.67 5.93 10 0 13.33 5.93 20 7.26 15.39 12.25 16.18 19 10 16.16"
        fill={fill}
      />
    </svg>
  );
}

Star10.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Star10.defaultProps = {
  fill: undefined,
  className: '',
  width: '',
  height: '',
};

export default Star10;
