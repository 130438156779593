import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import Container from 'ls-common-client/src/components/Container';
import CheckBox from 'ls-common-client/src/components/CheckBox';
import Text from 'ls-common-client/src/components/Text';
import Image from 'ls-common-client/src/components/Image';
import useForm from 'ls-common-client/src/hooks/useForm';
import { Response } from '../../../../../../images';
import { ToggleInput, TextArea, Button, FormRow } from '../../../../../common';

const mutationReviewResponse = gql`
  mutation UpdateReviewResponse($input: UpdateReviewResponseInput!) {
    updateReviewResponse(input: $input) {
      reviewResponse {
        reviewId
        message
      }
    }
  }
`;

const mutationRemoveReviewResponse = gql`
  mutation RemoveReviewResponse($reviewId: String!) {
    removeReviewResponse(reviewId: $reviewId) {
      success
    }
  }
`;

const OwnerResponseForm = ({
  reviewId,
  response,
  onDeleteSuccess,
  onUpdateSuccess,
}) => {
  const { message } = response || {};

  const [mutateReviewResponse, { loading }] = useMutation(
    mutationReviewResponse,
    {
      onCompleted: () => {
        onUpdateSuccess();
      },
    }
  );
  const [mutateRemoveResponse, { loading: removeLoading }] = useMutation(
    mutationRemoveReviewResponse,
    {
      onCompleted: () => {
        onDeleteSuccess();
      },
    }
  );

  const { setValue, values, reset, valid: formValid, changed } = useForm({
    defaults: {
      reviewId,
      message,
      remove: false,
    },
  });

  const onSubmit = useCallback(
    async payload => {
      try {
        const { remove, ...input } = payload;
        if (remove) {
          await mutateRemoveResponse({
            variables: {
              reviewId,
            },
          });
        } else {
          await mutateReviewResponse({
            variables: {
              input,
            },
          });
        }
      } catch (err) {
        reset();
        throw err;
      }
    },
    [values]
  );

  return (
    <form noValidate>
      <Container>
        <FormRow icon={<Image src={Response} alt="response" />}>
          <ToggleInput>
            <TextArea
              value={values.message || ''}
              onChange={setValue}
              name="message"
              maxLength="1000"
              pattern="^[a-zA-Z -]*$"
              placeholder="Owner Response"
              style={{
                fontSize: '16px',
                minHeight: '200px',
              }}
            />
            <Container display="flex" justifyContent="flex-end">
              <Text
                fontSize="11px"
                color="text300"
                display="block"
                textAlign="right"
                marginBottom="small"
              >
                {`${(values.message && values.message.length) || 0}/1000`}
              </Text>
            </Container>
          </ToggleInput>
        </FormRow>
      </Container>
      <Container
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Container
          display="flex"
          justifyContent="space-between"
          marginBottom="normal"
        >
          <Container marginTop="14px" marginLeft="large">
            <CheckBox
              className="checkbox"
              label="Remove"
              name="remove"
              value={values.remove}
              defaultChecked={values.remove}
              onChange={setValue}
            />
          </Container>
          <Button
            secondary
            marginLeft="auto"
            width="auto"
            fontWeight="normal"
            flex="1 0 47%"
            maxWidth="47%"
            type="button"
            onClick={() => onSubmit(values)}
            disabled={!formValid() || !changed()}
          >
            {loading || removeLoading
              ? `${loading ? `Saving...` : 'Removing...'}`
              : `Save`}
          </Button>
        </Container>
      </Container>
    </form>
  );
};

OwnerResponseForm.propTypes = {
  response: PropTypes.shape({}),
  reviewId: PropTypes.string,
  onDeleteSuccess: PropTypes.func,
  onUpdateSuccess: PropTypes.func,
};

OwnerResponseForm.defaultProps = {
  response: undefined,
  reviewId: undefined,
  onDeleteSuccess: () => {},
  onUpdateSuccess: () => {},
};

export default OwnerResponseForm;
