import React, { useContext } from 'react';
import Container from 'ls-common-client/src/components/Container';
import { AppContext } from '../../context';
import HelpMenu from './HelpMenu';
import { auth, imageResizer } from '../../services';

const UserMenu = () => {
  const {
    user: { user },
  } = useContext(AppContext.Context);

  return (
    <Container
      marginLeft="auto"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <HelpMenu />
      <UserMenu
        user={user}
        auth={auth}
        imageResizer={imageResizer}
        iconFill="#2f393e"
        marginLeft
      />
    </Container>
  );
};

export default UserMenu;
