const defaults = require('./defaults');

const { auth } = defaults;

module.exports = {
  ...defaults,
  hot: false,
  api: 'https://staging.services.lsapis.com.au',
  auth: {
    ...auth,
    domain: 'staging.auth.localsearch.cloud',
    audience: 'https://performance.lsapis.com.au',
    clientID: '6RJIGeWY1kXyRmwavFSitmZWZW8IAMRo',
    redirectUri: 'https://staging.reviews.lsapps.com.au',
  },
  imageResizeRoot: 'https://dkw8fcvpqki80.cloudfront.net',
  webUrlRoot: 'https://staging.ls-www.localsearch.cloud',
  deploy: {
    domain: 'staging.reviews.lsapps.com.au',
    zoneID: 'Z240AQP4P07IAB',
    certificateArn:
      'arn:aws:acm:us-east-1:789417954668:certificate/89122b4f-2018-4238-b0bc-3eb66714575b',
  },
};
