import localsearch from './localsearch.svg';
import Star01 from './ls-star-01';
import Star02 from './ls-star-02';
import Star03 from './ls-star-03';
import Star04 from './ls-star-04';
import Star05 from './ls-star-05';
import Star06 from './ls-star-06';
import Star07 from './ls-star-07';
import Star08 from './ls-star-08';
import Star09 from './ls-star-09';
import Star10 from './ls-star-10';
import Star from './ls-star-full';
import Comment from './comment.svg';
import Envelope from './envelope.svg';
import History from './history.svg';
import Information from './information.svg';
import Response from './response.svg';
import Time from './time.svg';
import User from './user.svg';
import AvatarPlaceholder from './avatar.png';

export {
  localsearch,
  Star01,
  Star02,
  Star03,
  Star04,
  Star05,
  Star06,
  Star07,
  Star08,
  Star09,
  Star10,
  Star,
  Comment,
  Envelope,
  History,
  Information,
  Response,
  Time,
  User,
  AvatarPlaceholder,
};
