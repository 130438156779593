import React from 'react';
import Container from 'ls-common-client/src/components/Container';
import Splash from 'ls-common-client/src/components/Splash';
import Image from 'ls-common-client/src/components/Image';
import { localsearch } from '../images';

const Intro = props => (
  <Container
    display="flex"
    flexDirection="column"
    backgroundColor="background800"
    alignItems="center"
    justifyContent="space-evenly"
    height="100vh"
    minHeight="100%"
    {...props}
  >
    <Container flex="1 1 100%" display="flex">
      <Image
        width="250px"
        src={localsearch}
        alt="Localsearch Reviews Manager"
      />
    </Container>
    <Splash />
    <Container flex="1 1 100%" />
  </Container>
);

export default Intro;
