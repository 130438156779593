import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Label from 'ls-common-client/src/components/Label';

const StyledLabel = styled(Label)`
  input[type='text'] {
    width: 100%;
    border-color: transparent;
    background-color: transparent;
    font-family: ${props => props.theme.font.circular};
    font-size: 16px;
    &:focus {
      border-color: ${props => props.theme.border.normal};
      background-color: ${props => props.theme.shade.shade100};
    }
  }
  textarea {
    border-radius: 4px;
    border-color: transparent;
    background-color: transparent;
    box-shadow: none;
    + span {
      transition: all 0.3s ease;
      opacity: 0;
    }
    &:focus {
      border-color: ${props => props.theme.border.normal};
      background-color: ${props => props.theme.shade.shade100};
    }
    &:focus,
    &:hover {
      + span {
        opacity: 1;
      }
    }
  }
`;

const ToggleInput = ({ children, ...props }) => {
  return (
    <StyledLabel
      display="flex"
      flexDirection="column"
      width="100%"
      marginBottom="small"
      {...props}
    >
      {children}
    </StyledLabel>
  );
};

ToggleInput.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ToggleInput;
