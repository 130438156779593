import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import { Accordion } from '../../../../common';
import filterProfileUrl from '../../../../../lib/filterProfileUrl';

const StyledContainer = styled(Container)`
  a {
    font-size: 14px;
    i {
      font-size: 28px;
    }
  }
`;

const StyledText = styled(Text)`
  color: ${props => props.theme.shade.shade700};
  font-size: 14px;
  padding-bottom: 4px;
`;

const AdditionalInfo = ({ review }) => {
  const { profile, user } = review || {};
  const { vanityUrlPath } = profile || {};
  const url = vanityUrlPath
    ? `https://www.localsearch.com.au/profile/${vanityUrlPath}`
    : null;
  const { suburb } = user || {};
  const { name: suburbName, state, market, postcode } = suburb || {};

  return (
    <StyledContainer display="flex" flexDirection="column" padding="small">
      <StyledText>
        {!!suburbName &&
          `User location: ${suburbName} ${state} ${postcode || ''}`}
      </StyledText>
      {url || market ? (
        <Accordion
          text={{
            open: 'Hide advanced info',
            close: 'View advanced info',
          }}
        >
          <StyledText>
            {vanityUrlPath && (
              <>
                {`Profile Url: `}
                <a
                  href={filterProfileUrl(url)}
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                >
                  {filterProfileUrl(url)}
                </a>
              </>
            )}
          </StyledText>
          <StyledText>{market && `Market: ${market}`}</StyledText>
        </Accordion>
      ) : (
        ''
      )}
    </StyledContainer>
  );
};

AdditionalInfo.propTypes = {
  review: PropTypes.shape({}),
};

AdditionalInfo.defaultProps = {
  review: null,
};

export default AdditionalInfo;
